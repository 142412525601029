import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import user from './user'
import courses from './courses'
import news from './news'
import events from './events'
import education from './education'
import test from './test'
import setting from './setting'
import administration from './administration'
import statistics from './statistics'
import userWorks from './user-works'
import userCertificate from './user-certificate'
import forms from './forms'
import feedbacks from './feedbacks'

import { AUTH_LOGOUT } from '@/store/action-types'

Vue.use(Router)

const isAuthenticated = async (to, from, next) => {
	if (store.state.auth.isAuthenticated && store.state.profile.isAdmin) {
		next();
		return;
	}
	
	store.dispatch(`auth/${AUTH_LOGOUT}`);
	next(`/login`);
}

const router = new Router({
	mode	: 'history',
	base	: process.env.BASE_URL,
	routes	: [
		{
			path		: '/:lang(.{2})?/login',
			name		: 'sing-in',
			component	: () => import('@/modules/auth/sing-in/index.vue')
		},
		{
			path		: '/:lang?/server-not-found', // 500
			name		: 'server-not-found',
			component	: () => import('@/modules/system/server-not-found/index.vue')
		},
		{
			path		: '/:lang?/page-not-found', // 404
			name		: 'page-not-found',
			component	: () => import('@/modules/system/page-not-found/index.vue')
		},
		{
			path		: '/:lang?/not-permission', // 403
			name		: 'not-permission',
			component	: () => import('@/modules/system/not-permission/index.vue')
		},
		{
			path		: '/:lang?/',
			name		: 'main',
			component	: () => import('@/modules/main/index.vue'),
			redirect	: '/:lang?/courses',
			beforeEnter	: isAuthenticated,
			children	: [
				{
					path		: 'dashboard',
					name		: 'dashboard',
					component	: () => import('@/modules/dashboard/index.vue')
				},
				{
					path		: 'reviews',
					name		: 'reviews',
					component	: () => import('@/modules/reviews/pages/index/index.vue')
				},
				{
					path		: 'reviews/:id',
					name		: 'reviews-id',
					component	: () => import('@/modules/reviews/pages/id/index.vue')
				},
				{
					path		: 'reviews/course',
					name		: 'reviews-course-noId',
					redirect	: '/:lang?/reviews'
				},
				{
					path		: 'reviews/course/:courseId',
					name		: 'reviews-course',
					component	: () => import('@/modules/reviews/pages/course/index.vue')
				},
				...test,
				...userWorks,
				...userCertificate,
				...education,
				...user,
				...courses,
				...news,
				...events,
				...setting,
				...administration,
				...statistics,
				...forms,
				...feedbacks
			]
		}
	],
	scrollBehavior (to, from, savedPosition) {
		const el = document.querySelector('.app');
		
		if (to.path !== from.path) {
			if (savedPosition) {
				return savedPosition
			} else {
				el.scrollTop = 0
				
				return {
					y: 0
				}
			}
		}
	}
})

export default router
