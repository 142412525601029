export function educationTariffsAccessTabs () {
	return [
		{
			name	: 'Webinars',
			slug	: 'webinars',
			urlKey	: 'webinar',
			id		: 1
		},
		{
			name	: 'Q/A sessions',
			slug	: 'qa-sessions',
			urlKey	: 'qa-session',
			id		: 2
		},
		{
			name	: 'Master classes',
			slug	: 'master-classes',
			urlKey	: 'master-class',
			id		: 3
		},
		{
			name	: 'Action plans',
			slug	: 'action-plans',
			urlKey	: 'action-plan',
			id		: 4
		},
		{
			name	: 'Bonuses',
			slug	: 'bonuses',
			urlKey	: 'bonus',
			id		: 5
		}
	]
}

export function educationTariffsTypes () {
	return [
		{
			text: 'Main',
			id	: 1
		},
		{
			text: 'Bonus',
			id	: 2
		},
		{
			text: 'Demo',
			id	: 3
		},
		{
			text: 'Free',
			id	: 4
		}
	]
}

export function educationTariffsTableHead () {
	return [
		{
			text	: 'Id',
			value	: 'id'
		},
		{
			text	: 'Name',
			value	: 'name'
		},
		{
			text	: 'Country',
			value	: 'country'
		},
		{
			text	: 'Days',
			value	: 'days'
		},
		{
			text	: 'Date',
			value	: 'dateEnd'
		},
		{
			text	: 'Type',
			value	: 'type'
		},
		{
			text	: 'Price',
			value	: 'price'
		},
		{
			text	: 'Status',
			value	: 'status'
		},
		{
			text	: 'Students',
			value	: 'students'
		},
		{
			text	: 'Actions',
			value	: 'actions',
			sortable: false
		}
	]
}
