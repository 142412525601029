export function coursePackagesTableHead () {
	return [
		{
			text	: '№',
			align	: 'start',
			sortable: false,
			value	: 'index'
		},
		{
			text	: 'Name',
			value	: 'name'
		},
		{
			text	: 'Students',
			value	: 'students'
		},
		{
			sortable: false,
			text	: 'Actions',
			value	: 'actions'
		}
	]
}
