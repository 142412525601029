import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import validation from '@/mixins/validation'

import mixins from '@/mixins'
import moment from 'moment'

import { filterProps } from '../../helpers'

export default {
	name	: 'position-filter',
	mixin	: [mixins, validation],
	data () {
		return {
			filter		: {
				keywords	: '',
				statusId	: '',
				typeId		: '',
				entityId	: 'tariff',
				providerId	: '',
				active		: '',
				dateFrom	: '',
				dateTo		: ''
			}
		}
	},
	computed: {
		...mapGetters({
			filters			: 'userTransactions/filter',
			
			accessCountries	: 'profile/accessCountries',
			
			isLoading       : 'userTransactions/isLoading',
            
            limit           : 'userTransactions/limit',
            skip            : 'userTransactions/skip',
            page            : 'userTransactions/page'
		}),
		...filterProps
	},
	created () {
		Object.assign(this.filter, this.filters)
	},
	methods: {
		...mapActions({
			fetchItems      : 'userTransactions/GET_USER_TRANSACTIONS'
		}),
		...mapMutations({
			changeSkip      : 'userTransactions/CHANGE_SKIP',
            changePage		: 'userTransactions/CHANGE_PAGE',
            changeFilter	: 'userTransactions/CHANGE_FILTER',
		}),
		sendRequest: debounce(function () {
			console.log('filter');
			console.log(this.filter);
			console.log('');
			
			this.changeSkip(this.limit * (1 - 1));
			this.changePage(1);
			
			this.fetchItems({
                userId		: 0,
                keywords	: this.filter.keywords,
				statusId	: this.filter.statusId,
				typeId		: this.filter.typeId,
				entityId	: this.filter.entityId,
				providerId	: this.filter.providerId,
				dateFrom	: this.filter.dateFrom,
				dateTo		: this.filter.dateTo
            })
		}, 500),
		changeFilterLocal (val, type, isReset = false) {
			this.filter[type] = val;
			
			this.changeFilter({
				isClear : false,
				type	: type, 
				value	: val
			});
			
			this.sendRequest();
		},
		formattedDate (date) {
            if (!date) return '';
            
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        },
		handleResetFilter () {
			//this.changeFilter(null)
			
			for (const i in this.filter) {
				this.filter[i] = '';
				
				this.changeFilter({
					isClear : true,
					type	: i, 
					value	: ''
				});
			}
			
			//this.filters.isChecking = '1';
			//this.search = '';
			
			this.$refs.datetimeFrom.clearHandler()
			this.$refs.datetimeTo.clearHandler()
		},
	},
	destroyed () {
		//this.changeFilter({ isClear: true })
	}
}
