export const SET_LIST = 'SET_LIST'
export const FETCH_LIST_LOADING = 'FETCH_LIST_LOADING'
export const SET_LIST_LENGTH = 'SET_LIST_LENGTH'
export const GET_LIST_LOADING = 'GET_LIST_LOADING'
export const FRESH_LIST = 'FRESH_LIST'
export const GET_CURRENT_LOADING = 'GET_CURRENT_LOADING'
export const SET_ITEM = 'SET_ITEM'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_SKIP = 'CHANGE_SKIP'
export const CHANGE_PAGE = 'CHANGE_PAGE'
export const UPDATE_USER_PASSWORD_LOADING = 'UPDATE_USER_PASSWORD_LOADING'
export const GET_SPEAKERS_LOADING = 'GET_SPEAKERS_LOADING'
export const SET_SPEAKERS = 'SET_SPEAKERS'
export const UPDATE_IMAGE_LOADING = 'UPDATE_IMAGE_LOADING'
export const FRESH_IMAGE = 'FRESH_IMAGE'
export const GET_USER_COURSES_LOADING = 'GET_USER_COURSES_LOADING'
export const SET_USER_COURSES = 'SET_USER_COURSES'
export const SET_USER_COURSES_LENGTH = 'SET_USER_COURSES_LENGTH'
export const USER_COURSES_CHANGE_SKIP = 'USER_COURSES_CHANGE_SKIP'
export const USER_COURSE_REMOVE_ITEM = 'USER_COURSE_REMOVE_ITEM'
export const CHANGE_LOADING = 'CHANGE_LOADING'
export const GET_BASIC_USERS_LOADING = 'GET_BASIC_USERS_LOADING'
export const SET_BASIC_USERS = 'SET_BASIC_USERS'
export const GET_TARIFFS_LOADING = 'GET_TARIFFS_LOADING'
export const SET_TARIFFS = 'SET_TARIFFS'
export const SET_USER_TARIFFS = 'SET_USER_TARIFFS'
export const SET_USER_TARIFFS_LENGTH = 'SET_USER_TARIFFS_LENGTH'
export const GET_USER_TARIFFS_LOADING = 'GET_USER_TARIFFS_LOADING'
export const USER_TARIFFS_REMOVE_ITEM = 'USER_TARIFFS_REMOVE_ITEM'
export const FRESH_USER_COUNTRIES = 'FRESH_USER_COUNTRIES'
export const CHANGE_DIALOG_VIEW = 'CHANGE_DIALOG_VIEW'
export const GET_PERMISSIONS_LOADING = 'GET_PERMISSIONS_LOADING'
export const SET_PERMISSIONS = 'SET_PERMISSIONS'
export const CHANGE_USER_PERMISSIONS = 'CHANGE_USER_PERMISSIONS'
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS'
export const REMOVE_LIST_ITEM = 'REMOVE_LIST_ITEM'
export const RESET_USER_PERMISSIONS = 'RESET_USER_PERMISSIONS'
export const SET_CURRENT_SERVICE_PERMISSIONS = 'SET_CURRENT_SERVICE_PERMISSIONS'
export const FRESH_USER_ROLES = 'FRESH_USER_ROLES'
export const FRESH_USER_PERMISSIONS = 'FRESH_USER_PERMISSIONS'
export const GET_USERS_LOADING = 'GET_USERS_LOADING'
export const SET_USERS = 'SET_USERS'
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS'
export const SET_UPDATED_CONTACTS = 'SET_UPDATED_CONTACTS'
export const SET_CONTACTS_CHANGES = 'SET_CONTACTS_CHANGES'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_USERS_SETTING = 'SET_USERS_SETTING'
export const USER_CARDS_REMOVE_ITEM = 'USER_CARDS_REMOVE_ITEM'
export const SET_USER_CARDS = 'SET_USER_CARDS'
export const SET_USER_CARDS_LENGTH = 'SET_USER_CARDS_LENGTH'
export const SET_USER_TRANSACTIONS = 'SET_USER_TRANSACTIONS'
export const SET_USER_TRANSACTIONS_LENGTH = 'SET_USER_TRANSACTIONS_LENGTH'
export const USER_TRANSACTIONS_REMOVE_ITEM = 'USER_TRANSACTIONS_REMOVE_ITEM'
export const SET_USER_STATISTICS = 'SET_USER_STATISTICS'
export const USER_SCHEDULE_REMOVE_ITEM = 'USER_SCHEDULE_REMOVE_ITEM'
export const SET_USER_SCHEDULE = 'SET_USER_SCHEDULE'
export const SET_USER_SCHEDULE_LENGTH = 'SET_USER_SCHEDULE_LENGTH'
