import { $http } from '@/utils/https'
import Vue from 'vue'

import {
    CHANGE_SKIP,
    CHANGE_PAGE,
    CHANGE_FILTER,
    USER_TRANSACTIONS_REMOVE_ITEM,
    CHANGE_LOADING,
    SET_USER_TRANSACTIONS,
    SET_USER_TRANSACTIONS_LENGTH,
    SET_USER_STATISTICS
} from './types/mutation-types'

import {
    GET_USER_TRANSACTIONS,
    GET_TRANSACTIONS_STATISTIC,
    DELETE,
    REVERSE,
    CONTINUE_PAY
} from './types/action-types'

const state = {
    items               : [],
    itemsLength         : 0,
    isLoading           : false,
    skip                : 0,
    limit               : 15,
    page				: 1,
    filter				: {
		keywords	: '',
		statusId	: '',
		typeId		: '',
		entityId	: 'tariff',
		providerId	: '',
		active		: '',
		dateFrom	: '',
		dateTo		: ''
	},
    statistics       	: {
		dates	: [],
		wait	: 0,
		success	: 0
	}
}

const getters = {
    items               : state => state.items,
    itemsLength         : state => state.itemsLength,
    isLoading           : state => state.isLoading,
    skip                : state => state.skip,
    limit               : state => state.limit,
    page               	: state => state.page,
    filter         		: state => state.filter
}

const actions = {
    [GET_USER_TRANSACTIONS]     	: async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            var url = `${process.env.VUE_APP_ROOT_PAYMENTS}users/${payload.userId}/transactions?`;
            
            url += `limit=${(payload.limit ? payload.limit : state.limit)}`;
            url += '&';
            url += `skip=${(payload.skip ? payload.skip : state.skip)}`;
            
            if(payload.keywords){
				url += '&';
				url += `filters[keywords]=${payload.keywords}`;
			}
			
			if(payload.statusId){
				url += '&';
				url += `filters[status]=${payload.statusId}`;
			}
			
			if(payload.typeId){
				url += '&';
				url += `filters[type]=${payload.typeId}`;
			}
			
			if(payload.entityId){
				url += '&';
				url += `filters[entity]=${payload.entityId}`;
			}
			
			if(payload.providerId){
				url += '&';
				url += `filters[provider]=${payload.providerId}`;
			}
			
			if(payload.dateFrom){
				url += '&';
				url += `filters[date_from]=${payload.dateFrom}`;
			}
			
			if(payload.dateTo){
				url += '&';
				url += `filters[date_to]=${payload.dateTo}`;
			}
            
            console.log('url');
			console.log(url);
			console.log('');
            
            const response = await $http.get(url);
            
            if(response && response.data && 'data' in response.data){
                commit(SET_USER_TRANSACTIONS         , response.data.data)
                commit(SET_USER_TRANSACTIONS_LENGTH  , response.data.meta.total)
            }else{
                commit(SET_USER_TRANSACTIONS         , [])
                commit(SET_USER_TRANSACTIONS_LENGTH  , 0)
            }
        } catch (e) {
            console.log(e);
            //throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [GET_TRANSACTIONS_STATISTIC]	: async ({ commit }, payload) => {
        try {
            //commit(CHANGE_LOADING, true)
            
            var url = `${process.env.VUE_APP_ROOT_EDUCATION}tariffs/statistics`;
            
            console.log('url');
			console.log(url);
			console.log('');
            
            const response = await $http.get(url);
            
            if(response && response.data && 'data' in response.data){
                commit(SET_USER_STATISTICS, response.data.data)
            }
        } catch (e) {
			console.log(e);
            //throw e
        } finally {
            //commit(CHANGE_LOADING, false)
            
            commit(SET_USER_STATISTICS         , {
				dates	: [],
				wait	: 0,
				success	: 0
			})
        }
    },
    [DELETE]                    	: async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            await $http.delete(`${process.env.VUE_APP_ROOT_PAYMENTS}users/${payload.userId}/transactions/${payload.id}`)
            
            commit(USER_TRANSACTIONS_REMOVE_ITEM, payload)
        } catch (e) {
            //throw e
            console.log('DELETE:');
            console.log(e);
            console.log('');
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [REVERSE]                   	: async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            await $http.post(`${process.env.VUE_APP_ROOT_PAYMENTS}users/${payload.userId}/transactions/${payload.id}/reverse`)
            
            commit(USER_TRANSACTIONS_REMOVE_ITEM, payload)
        } catch (e) {
            //throw e
            console.log('REVERSE:');
            console.log(e);
            console.log('');
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [CONTINUE_PAY]             		: async ({ commit }, payload) => {
		console.log('CONTINUE_PAY:');
		console.log(payload);
		console.log('');
		
		//return;
		
        try {
            commit(CHANGE_LOADING, true)
            
            await $http.post(
				`${process.env.VUE_APP_ROOT_EDUCATION}tariffs/continue`,
				{
					id		: 0,
					payId	: payload.id,
					userId	: payload.userId ? payload.userId : 0,
					entityId: payload.entityId
				}
			)
            
            commit(USER_TRANSACTIONS_REMOVE_ITEM, payload)
        } catch (e) {
            console.log('CONTINUE_PAY:');
            console.log(e);
            console.log('');
        } finally {
            commit(CHANGE_LOADING, false)
        }
    }
}

const mutations = {
    [SET_USER_TRANSACTIONS] (state, list) {
        state.items = list
    },
    [SET_USER_STATISTICS] (state, payload) {
        state.statistics = payload
    },
    [SET_USER_TRANSACTIONS_LENGTH] (state, length) {
        state.itemsLength = length
    },
    [CHANGE_SKIP] (state, count) {
        state.skip = count
    },
    [CHANGE_PAGE] (state, page) {
        state.page = page
    },
    [CHANGE_LOADING] (state, status) {
        state.isLoading = status
    },
	[CHANGE_FILTER] (state, params) {
		const { isClear, type, value } = params;
		
		console.log('CHANGE_FILTER');
		console.log(params);
		console.log('');
		
		if (!isClear) {
			state.filter[type] = value
		} else {
			Object.keys(state.filter).forEach(e => {
				state.filter[e] = ''
			})
		}
	},
    [USER_TRANSACTIONS_REMOVE_ITEM] (state, params) {
        const index = state.items.findIndex(e => e.card_id === params.id)
        
        Vue.delete(state.items, index)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
