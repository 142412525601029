import { $http } from '@/utils/https'
import Vue from 'vue'

import {
    CHANGE_SKIP,
    CHANGE_PAGE,
    USER_SCHEDULE_REMOVE_ITEM,
    CHANGE_LOADING,
    SET_USER_SCHEDULE,
    SET_USER_SCHEDULE_LENGTH,
} from './types/mutation-types'

import {
    GET_USER_SCHEDULE,
    DELETE,
    CREATE,
    UPDATE
} from './types/action-types'

const state = {
    items               : [],
    itemsLength         : 0,
    isLoading           : false,
    skip                : 0,
    limit               : 15,
    page				: 1
}

const getters = {
    items               : state => state.items,
    itemsLength         : state => state.itemsLength,
    isLoading           : state => state.isLoading,
    skip                : state => state.skip,
    limit               : state => state.limit,
    page               	: state => state.page
}

const actions = {
    [GET_USER_SCHEDULE]     		: async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            var url = `${process.env.VUE_APP_ROOT_EDUCATION}tariffs/schedule/${payload.userId}?`;
            
            url += `limit=${(payload.limit ? payload.limit : state.limit)}`;
            url += '&';
            url += `skip=${(payload.skip ? payload.skip : state.skip)}`;
            
            const response = await $http.get(url);
            
            if(response && response.data && 'data' in response.data){
                commit(SET_USER_SCHEDULE         , response.data.data)
                commit(SET_USER_SCHEDULE_LENGTH  , response.data.meta.total)
            }else{
                commit(SET_USER_SCHEDULE         , [])
                commit(SET_USER_SCHEDULE_LENGTH  , 0)
            }
        } catch (e) {
            console.log(e);
            //throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [DELETE]                    	: async ({ dispatch, commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            await $http.delete(`${process.env.VUE_APP_ROOT_EDUCATION}tariffs/schedule/${payload.id}`)
            
            commit(USER_SCHEDULE_REMOVE_ITEM, payload)
            
            dispatch(
				GET_USER_SCHEDULE, 
				{
					userId : payload.userId
				}
			)
        } catch (e) {
            //throw e
            
            console.log('DELETE:');
            console.log(e);
            console.log('');
            
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [UPDATE]                    	: async ({ dispatch, commit }, payload) => {
		//console.log('UPDATE:');
		//console.log(payload);
		//console.log('');
		
		//return;
		
        try {
            commit(CHANGE_LOADING, true)
            
            //const response = await $http.patch(`${process.env.VUE_APP_ROOT_EDUCATION}tariffs/schedule/update/${payload.userId}`, payload)
            const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tariffs/schedule/update/${payload.id}`, payload)
            
            //commit(SET_ITEM, response.data.data)
            
            dispatch(
				GET_USER_SCHEDULE, 
				{
					userId : payload.userId
				}
			)
        } catch (e) {
            //throw e
            
            console.log('UPDATE:');
            console.log(e);
            console.log('');
            
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [CREATE]                    	: async ({ dispatch, commit }, payload) => {
		//console.log('CREATE:');
		//console.log(payload);
		//console.log('');
		
		//return;
		
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tariffs/schedule/create/${payload.userId}`, payload)
            
            //commit(SET_ITEM, response.data.data)
            
            //dispatch(GET_LIST_ITEM, response.data.data)
            
            dispatch(
				GET_USER_SCHEDULE, 
				{
					userId : payload.userId
				}
			)
        } catch (e) {
            //throw e
            
            console.log('CREATE:');
            console.log(e);
            console.log('');
            
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    }
}

const mutations = {
    [SET_USER_SCHEDULE] (state, list) {
        state.items = list
    },
    [SET_USER_SCHEDULE_LENGTH] (state, length) {
        state.itemsLength = length
    },
    [CHANGE_SKIP] (state, count) {
        state.skip = count
    },
    [CHANGE_PAGE] (state, page) {
        state.page = page
    },
    [CHANGE_LOADING] (state, status) {
        state.isLoading = status
    },
    [USER_SCHEDULE_REMOVE_ITEM] (state, params) {
        const index = state.items.findIndex(e => e.id === params.id)
        
        Vue.delete(state.items, index)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
