import { mapActions, mapGetters, mapMutations } from 'vuex'

import CountryFlag from 'vue-country-flag'
import DialogView from '../dialog-view/index.vue'
import PositionFilter from '../../components/filters/index.vue'

import { positionTableHead } from '../../helpers'

import validationMixin from '@/mixins/validation'
import { debounce } from 'lodash'
import moment from 'moment'

export default {
	name		: 'user-payments-list',
	data () {
		return {
			valid                   : false,
            page                    : 1,
            //limit                   : 100,
            showRemoveDialog        : false,
            showReverseDialog       : false,
            showContinueDialog		: false,
            deletedItem             : null,
            updateItemData          : null,
            //userTransactionsLength  : 0,
			spinnerReverse          : [],
			spinnerContinue			: [],
			isTester				: false
		}
	},
	components	: {
		PositionFilter,
		DialogView,
		CountryFlag
	},
	computed	: {
		...mapGetters({
            items           : 'userTransactions/items',
            itemsLength     : 'userTransactions/itemsLength',
            
            isLoading       : 'userTransactions/isLoading',
            
            limit           : 'userTransactions/limit',
            skip            : 'userTransactions/skip',
            pageFilter   	: 'userTransactions/page',
            
            filter			: 'userTransactions/filter',
            
            statistics		: 'userTransactions/statistics'
        }),
		positionTableHead
	},
	watch		: {
		skip () {
			console.log('skip');
			console.log(this.skip);
			console.log('');
		},
		page () {
			console.log('page');
			console.log(this.page);
			console.log('');
			
			this.changePage(this.page);
		},
		pageFilter () {
			console.log('pageFilter');
			console.log(this.pageFilter);
			console.log('');
			
			if(this.pageFilter != this.page){
				this.page = this.pageFilter;
			}
		}
	},
    created () {
        //if (!this.items.length) {
            this.fetchItems({
                userId  	: 0,
                entityId	: this.filter.entityId
            })
        //}
        
        if (this.items) {
            if (this.skip !== 0) {
				this.page = Math.round(this.skip / this.limit) + 1;
				
                this.changePage(this.page);
            }
        }
        
        this.fetchStatistics();
    },
    methods: {
        ...mapActions({
            fetchItems      : 'userTransactions/GET_USER_TRANSACTIONS',
            fetchStatistics	: 'userTransactions/GET_TRANSACTIONS_STATISTIC',
            remove          : 'userTransactions/DELETE',
            reverse         : 'userTransactions/REVERSE',
            continuePay   	: 'userTransactions/CONTINUE_PAY'
        }),
        ...mapMutations({
            changeSkip      : 'userTransactions/CHANGE_SKIP',
            changePage		: 'userTransactions/CHANGE_PAGE',
        }),
        removeItem () {
			console.log('removeItem');
			console.log(this.deletedItem);
			console.log('');
			
			//return false;
			
            this.remove({
                userId  : this.deletedItem.user ? this.deletedItem.user.id : 0,
                id      : this.deletedItem.id
            })
            .then(() => {
                this.showRemoveDialog = false;
                
                this.fetchItems({
                    userId  : 0
                })
                
                this.$toasted.success(this.$t('success_deleted'));
            })
        },
        reverseItem () {
			console.log('reverseItem');
			console.log(this.updateItemData);
			console.log('');
			
			//return false;
			
            this.reverse({
                userId  : this.updateItemData.user ? this.updateItemData.user.id : 0,
                id      : this.updateItemData.id
            })
            .then(() => {
                this.showReverseDialog = false;
                
                this.fetchItems({
                    userId  : 0
                })
                
                this.$toasted.success(this.$t('success'));
            })
        },
        continueItem () {
			console.log('continueItem');
			console.log(this.updateItemData);
			console.log('');
			
			//return false;
			
            this.continuePay({
                userId  	: (this.updateItemData.user && this.updateItemData.user.data) ? this.updateItemData.user.data.id : 0,
                id      	: this.updateItemData.id,
                entityId	: this.updateItemData.entity_id
            })
            .then(() => {
                this.showContinueDialog = false;
                
                setTimeout(()=>{
					this.fetchItems({
						userId  : 0
					})
				}, 10000);
                
                this.$toasted.success(this.$t('success'));
            })
        },
        changeDialogDelete (item = null) {
            this.showRemoveDialog   = !!item;
            this.deletedItem        = item;
        },
        changeDialogReverse (item = null, status = false) {
            if(item == null){
                this.showReverseDialog  = false;
                this.updateItemData     = null;
                return;
            }
            
            const index = this.spinnerReverse.indexOf(item.id);
            
            if(index != -1){
                return;
            }
            
            this.showReverseDialog  = status;
            this.updateItemData     = item;
        },
        changeDialogContinue (item = null, status = false) {
            if(item == null){
                this.showContinueDialog		= false;
                this.updateItemData			= null;
                return;
            }
            
            const index = this.spinnerContinue.indexOf(item.id);
            
            if(index != -1){
                return;
            }
            
            this.showContinueDialog		= status;
            this.updateItemData			= item;
        },
        changePagination (index) {
			console.log('changePagination');
			console.log(index);
			console.log('');
			
            this.changeSkip(this.limit * (index - 1));
            
            if (this.skip !== 0) {
                //this.changePage(Math.round(this.skip / this.limit) + 1);
			}
            
            //this.$vuetify.goTo(0);
            
            this.fetchItems({
                userId  	: 0,
                limit   	: this.limit,
                keywords	: this.filter.keywords,
				statusId	: this.filter.statusId,
				typeId		: this.filter.typeId,
				entityId	: this.filter.entityId,
				dateFrom	: this.filter.dateFrom,
				dateTo		: this.filter.dateTo
            });
        },
        formattedDate (date) {
            if (!date) return '';
            
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        },
        createRequestPayload () {
            const formData = new FormData();
            
            //formData.append('available_to'  , this.formattedDate(this.availableTo));
            formData.append('userId'        , this.$route.params.id);
            
            if (this.editedItem) {
                formData.append('_method'       , 'patch');
                formData.append('id'            , this.editedItem.id);
            }else{
                //formData.append('tariff_id'       , this.payload.id);
            };
            
            return formData;
        },
        clickItem(item){
			console.log('clickItem:');
			console.log(item);
			console.log('');
		}
    },
    destroyed () {
        //this.setUserTariffs([]);
        //this.setTariffs([]);
    }
}
