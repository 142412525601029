import { mapActions, mapGetters, mapMutations } from 'vuex'
import validationMixin from '@/mixins/validation'
import { debounce } from 'lodash'
import moment from 'moment'

export default {
    name    	: 'user-students-schedule',
    mixins  	: [validationMixin],
    data () {
        return {
            valid                   : false,
            page                    : 1,
            //limit                   : 100,
            showRemoveDialog        : false,
            deletedItem             : null,
            showDialogForm			: false,
            openDialogView			: false,
            editedItem      		: null,
            keywords        		: '',
            search          		: null,
            searchInput     		: null,
            dateInvalid     		: false,
            nextAt     				: '',
            payload                 : {
                scheduleId              : '',
                tariffId				: '',
                tryDate					: '',
                amount					: '',
                successfully        	: false,
                failed          		: false,
                attempts          		: ''
            }
        }
    },
    computed	: {
        ...mapGetters({
            items           : 'userSchedule/items',
            itemsLength     : 'userSchedule/itemsLength',
            
            isLoading       : 'userSchedule/isLoading',
            
            limit           : 'userSchedule/limit',
            skip            : 'userSchedule/skip',
            
            tariffs               : 'userTariffs/tariffs',
            isTariffsLoading      : 'userTariffs/isTariffsLoading'
        }),
        headers () {
            return [
                {
                    text    : 'ID',
                    value   : 'id'
                },
                {
                    text    : 'Created at',
                    value   : 'created_at'
                },
                {
                    text    : 'Updated at',
                    value   : 'updated_at'
                },
                {
                    text    : 'Next at',
                    value   : 'next_at'
                },
                {
                    text    : 'Try date',
                    value   : 'try_date'
                },
                {
                    text    : 'Attempts',
                    value   : 'attempts'
                },
                {
                    text    : 'Amount',
                    value   : 'amount'
                },
                {
                    text    : 'Currency',
                    value   : 'currency'
                },
                {
                    text    : 'Successfully',
                    value   : 'successfully'
                },
                {
                    text    : 'Failed',
                    value   : 'failed'
                },
                {
                    text    : 'Provider',
                    value   : 'provider'
                },
                {
                    text    : 'Tariff ID',
                    value   : 'tariff_id'
                },
                {
                    text    : 'Tariff',
                    value   : 'tariff'
                },
                {
                    text    : 'Actions',
                    value   : 'actions',
                    sortable: false
                }
            ]
        }
    },
    watch		: {
        nextAt	: {
            handler(val, oldVal) {
                this.validateDate();
            },
            deep: true
        },
        tariffs	: {
            handler(val, oldVal) {
				if (this.tariffs.length && this.editedItem){
					for(let i = 0; i < this.tariffs.length; i++){
						if(this.tariffs[i].id == this.editedItem.tariff_id){
							this.search = this.tariffs[i];
							
							break;
						}
					}
				}
            },
            deep: true
        }
    },
    created () {
        //if (!this.items.length) {
            this.fetchItems({
                userId  : this.$route.params.id
            })
        //}
        
        if (this.items) {
            if (this.skip !== 0) {
                this.page = Math.round(this.skip / this.limit) + 1
            }
        }
        
        if (!this.tariffs.length){
            this.fetchTariffs({
                keywords    : '',
                loadAll     : true
            })
        }
        
        this.validateDate();
        
        this.$on('event-save', () =>{
            this.submit();
        });
    },
    methods		: {
        ...mapActions({
			fetchTariffs    : 'userTariffs/GET_TARIFFS',
            fetchItems      : 'userSchedule/GET_USER_SCHEDULE',
            remove          : 'userSchedule/DELETE',
            
            update          : 'userSchedule/UPDATE',
            create          : 'userSchedule/CREATE'
        }),
        ...mapMutations({
            changeSkip      : 'userSchedule/CHANGE_SKIP',
            setTariffs      : 'userTariffs/SET_TARIFFS'
        }),
        changeInputTariff: debounce(function (val) {
            if (!val) return;
            
            if(this.search && this.search.name == val){
                this.keywords = val;
                return;
            }
            
            if (this.keywords !== val) {
                this.keywords = val;
                
                if(false){
                    this.fetchTariffs({
                        keywords: val,
                        id      : this.payload.tariffId,
                        //limit   : this.limitTariffs
                    })
                }
            }
        }, 500),
        autoCompleteChanged (e) {
            this.payload.tariffId   = e.id;
            
            this.search             = e;
        },
        removeItem () {
            this.remove({
                userId  : this.$route.params.id,
                id      : this.deletedItem.id
            })
            .then(() => {
                this.changeDialogDelete(null);
                
                this.fetchItems({
                    userId  : this.$route.params.id
                })
                
                this.$toasted.success(this.$t('success_deleted'));
            })
        },
        changeDialogDelete (item = null) {
            this.showRemoveDialog   = !!item;
            this.deletedItem        = item;
        },
        changeDialogAction (item = null, status = false) {
            this.showDialogForm   	= status;
            this.editedItem         = item;
            
            if (!status) {
                this.payload.scheduleId		= '';
                this.payload.tariffId		= '';
                this.payload.amount			= '';
                
                this.keywords				= '';
                
                this.nextAt            		= '';
                
                this.search         = null;
                this.searchInput    = null;
            }
            
            if (status && item != null) {
				if (!this.tariffs.length){
					this.fetchTariffs({
						keywords    : '',
						loadAll     : true
					})
				}
				
                this.search         = null;
                this.searchInput    = null;
                
                if (this.tariffs.length){
					for(let i = 0; i < this.tariffs.length; i++){
						if(this.tariffs[i].id == item.tariff_id){
							this.search = this.tariffs[i];
							
							break;
						}
					}
				}
                
                this.keywords       		= item.tariff.data.name;
                
                this.payload.scheduleId  	= item.id;
                this.payload.tariffId  		= item.tariff_id;
                this.payload.amount  		= item.amount;
                
                this.payload.successfully	= item.successfully;
                this.payload.failed			= item.failed;
                
                this.payload.attempts		= item.attempts;
                
                this.nextAt        			= item.next_at;
                this.tryDate        		= item.try_date;
            }
            
            this.validateDate();
        },
        changePagination (index) {
            this.changeSkip(this.limit * (index - 1));
            
            this.$vuetify.goTo(0);
            
            this.fetchItems({
                userId  : this.$route.params.id,
                limit   : 300
            });
        },
        formattedDate (date) {
            if (!date) return '';
            
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        },
        createRequestPayload () {
            //const formData = new FormData();
            const formData  = {};
            
            //formData.append('next_at'  		, this.formattedDate(this.nextAt));
            formData.next_at	= this.formattedDate(this.nextAt);
            
            //formData.append('userId'        , this.$route.params.id);
            formData.userId		= this.$route.params.id;
            
            if (this.editedItem) {
                //formData.append('_method'       , 'patch');
                //formData._method		= 'patch';
                
                //formData.append('id'            , this.editedItem.id);
                formData.id				= this.editedItem.id;
                
                //formData.append('try_date'  	, this.formattedDate(this.tryDate));
                formData.try_date		= this.formattedDate(this.tryDate);
                
                //formData.append('successfully'	, this.payload.successfully ? 1 : 0);
                formData.successfully	= this.payload.successfully ? 1 : 0;
                
                //formData.append('failed'		, this.payload.failed ? 1 : 0);
                formData.failed			= this.payload.failed ? 1 : 0;
                
                //formData.append('attempts'     	, this.payload.attempts);
                formData.attempts		= this.payload.attempts;
            }
            
            //formData.append('tariff_id'     	, this.payload.tariffId);
            formData.tariff_id	= this.payload.tariffId;
            
            //formData.append('amount'     		, this.payload.amount);
            formData.amount		= this.payload.amount;
            
            return formData;
        },
        validateDate(){
            if(this.nextAt){
                const currentDate = new Date();
                
                if(this.nextAt < currentDate){
                    this.dateInvalid = true;
                }else{
                    this.dateInvalid = false;
                }
            }else{
                this.dateInvalid = false;
            }
        },
		submit () {
            this.$refs.form.validate();
            this.$refs.observer.validate();
            
            if (this.valid) {
                this.validateDate();
                
                if(this.dateInvalid){
                    return false;
                };
                
                if (this.editedItem) {
                    this.update(this.createRequestPayload())
                    .then(() => {
                        this.$toasted.success(this.$t('Success'));
                        
                        this.changeDialogAction(null, false);
                    })
                } else {
                    this.create(this.createRequestPayload())
                    .then(() => {
                        this.$toasted.success(this.$t('Success'));
                        
                        this.changeDialogAction(null, false);
                    })
                }
            }
        }
    },
    destroyed () {
        this.setTariffs([]);
    }
}
